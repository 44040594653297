import { jsx as _jsx } from "react/jsx-runtime";
import { ListItem as ChListItem, OrderedList as ChOrderedList } from '@chakra-ui/react';
export const OrderedList = (props) => {
    const { children } = props;
    return _jsx(ChOrderedList, { sx: { listStyle: 'none', counterReset: 'item' }, children: children });
};
export const ListItem = (props) => {
    const { children } = props;
    return (_jsx(ChListItem, { as: 'li', fontSize: 'sm', pb: 2, sx: { counterIncrement: 'item' }, _before: {
            content: 'counter(item)',
            display: 'inline-block',
            textAlign: 'center',
            bg: 'gray.700',
            color: 'white',
            borderRadius: 'full',
            width: '22px',
            mr: 2,
        }, ...props, children: children }));
};
