import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["domain", "hideSetupSteps", "onDelete", "alignBadgeLeft"];
var __jsx = React.createElement;
import { Badge, Button, HStack, IconButton, Text, TrashIcon } from '@playful/design_system';
import React, { useState } from 'react';
import CustomDomainDeleteDialog from './CustomDomainDeleteDialog';
import CustomDomainSetupDialog from './CustomDomainSetupDialog';
export function CustomDomainBlock(_ref) {
  var domain = _ref.domain,
    hideSetupSteps = _ref.hideSetupSteps,
    onDelete = _ref.onDelete,
    alignBadgeLeft = _ref.alignBadgeLeft,
    hStackProps = _objectWithoutProperties(_ref, _excluded);
  var _useState = useState(null),
    selectedDomain = _useState[0],
    setSelectedDomain = _useState[1];
  var _useState2 = useState(false),
    showCustomDomainSetupInfo = _useState2[0],
    setShowCustomDomainSetupInfo = _useState2[1];
  var _useState3 = useState(false),
    showDeleteCustomDomain = _useState3[0],
    setShowDeleteCustomDomain = _useState3[1];
  function handleDeleteCustomDomain(domain) {
    setShowDeleteCustomDomain(true);
    setSelectedDomain(domain);
  }
  function handleViewSetupSteps(domain) {
    setShowCustomDomainSetupInfo(true);
    setSelectedDomain(domain);
  }
  return __jsx(React.Fragment, null, __jsx(HStack, _extends({
    justifyContent: 'space-between'
  }, hStackProps), __jsx(HStack, null, __jsx(Text, {
    color: "gray.700",
    userSelect: 'text'
  }, domain.name), domain.redirect && __jsx(Text, {
    color: "gray.400",
    fontWeight: "medium",
    fontSize: "sm"
  }, "(redirect to ", domain.redirect, ")"), alignBadgeLeft && __jsx(StatusBadge, {
    status: domain.status
  })), __jsx(HStack, null, domain.status.toLowerCase() !== 'active' && __jsx(React.Fragment, null, !hideSetupSteps && __jsx(Button, {
    variant: "link",
    textDecoration: "underline",
    color: "gray.700",
    fontWeight: "medium",
    fontSize: "sm",
    onClick: function onClick() {
      handleViewSetupSteps(domain);
    }
  }, "view setup steps"), !alignBadgeLeft && __jsx(StatusBadge, {
    status: domain.status
  })), __jsx(IconButton, {
    variant: "ghost",
    size: "xs",
    "aria-label": "delete component",
    icon: __jsx(TrashIcon, null),
    onClick: function onClick() {
      handleDeleteCustomDomain(domain);
    }
  }))), showCustomDomainSetupInfo && __jsx(CustomDomainSetupDialog, {
    isOpen: showCustomDomainSetupInfo,
    onClose: function onClose() {
      return setShowCustomDomainSetupInfo(false);
    },
    domain: domain
  }), showDeleteCustomDomain && selectedDomain && __jsx(CustomDomainDeleteDialog, {
    domain: selectedDomain,
    isOpen: showDeleteCustomDomain,
    onClose: function onClose() {
      setShowDeleteCustomDomain(false);
      setSelectedDomain(null);
    },
    onDelete: onDelete
  }));
}
function StatusBadge(_ref2) {
  var status = _ref2.status;
  return __jsx(Badge, {
    variant: "solid",
    bg: "yellow.100",
    color: "gray.700",
    size: "sm"
  }, status === 'PROVISIONING' ? 'verifying' : status.toLowerCase());
}