import { jsx as _jsx } from "react/jsx-runtime";
import { FormLabel as ChFormLabel, FormControl as ChFormControl, FormErrorMessage as ChFormErrorMessage, } from '@chakra-ui/form-control';
import { forwardRef } from '@chakra-ui/system';
export const FormLabel = forwardRef((props, ref) => {
    return _jsx(ChFormLabel, { ref: ref, ...props });
});
export const FormControl = forwardRef((props, ref) => {
    return _jsx(ChFormControl, { ref: ref, ...props });
});
export const FormErrorMessage = forwardRef((props, ref) => {
    return _jsx(ChFormErrorMessage, { ref: ref, ...props });
});
