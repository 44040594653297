import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["isOpen", "onClose", "Footer", "domain", "hideSetupSteps", "isPublishing", "title"];
var __jsx = React.createElement;
import { Box, Link, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, OrderedList, Text } from '@playful/design_system';
import React from 'react';
import { CustomDomainBlock } from './CustomDomainBlock';
export default function CustomDomainSetupDialog(_ref) {
  var isOpen = _ref.isOpen,
    onClose = _ref.onClose,
    Footer = _ref.Footer,
    domain = _ref.domain,
    hideSetupSteps = _ref.hideSetupSteps,
    isPublishing = _ref.isPublishing,
    _ref$title = _ref.title,
    title = _ref$title === void 0 ? 'setup steps' : _ref$title,
    modalProps = _objectWithoutProperties(_ref, _excluded);
  // Find the A record for the domain
  var dnsEntry = domain.dnsEntries.find(function (entry) {
    return entry.record_type === 'A';
  });
  var domainParts = domain.name.split('.');
  var hostName = domainParts.length > 2 ? domainParts.slice(0, -2).join('.') : '@';
  return __jsx(Modal, _extends({
    isOpen: isOpen,
    onClose: onClose,
    isCentered: true
  }, modalProps), __jsx(ModalOverlay, null), __jsx(ModalContent, {
    maxW: "650px",
    onCopy: function onCopy(e) {
      return e.stopPropagation();
    }
  }, __jsx(ModalHeader, {
    pt: 10,
    pb: 0,
    px: 10
  }, title, __jsx(ModalCloseButton, null)), __jsx(ModalBody, {
    m: 10,
    mt: 6,
    p: 0
  }, isPublishing && __jsx(CustomDomainBlock, {
    domain: domain,
    onDelete: onClose,
    hideSetupSteps: true,
    alignBadgeLeft: true,
    mb: 3
  }), __jsx(Box, {
    borderColor: "gray.200",
    borderWidth: "1px",
    borderRadius: "md",
    p: 4
  }, __jsx(Box, {
    mb: 5
  }, __jsx(Text, {
    size: "md",
    color: "gray.700",
    fontWeight: "bold"
  }, "Next steps:")), __jsx(Box, {
    mb: 5
  }, __jsx(Text, {
    color: "gray.700",
    size: "sm"
  }, "To completely setup your domain to work with Hatch, you must configure your domain DNS settings to point the domain to Hatch.")), __jsx(Box, {
    mb: 5
  }, __jsx(OrderedList, null, __jsx(ListItem, null, "Sign into your registrar account and locate the option to \u2018manage DNS\u2019"), __jsx(ListItem, null, __jsx(Text, {
    as: "span",
    fontWeight: "bold"
  }, "Add a new DNS record:"), __jsx(Box, {
    mt: 0,
    ml: 8
  }, __jsx(Box, null, "Host Name:", ' ', __jsx(Text, {
    as: "span",
    fontWeight: "bold"
  }, hostName)), __jsx(Box, null, "Record Type:", ' ', __jsx(Text, {
    as: "span",
    fontWeight: "bold"
  }, "A")), __jsx(Box, null, "Value/Data/Address:", ' ', __jsx(Text, {
    as: "span",
    fontWeight: "bold"
  }, dnsEntry === null || dnsEntry === void 0 ? void 0 : dnsEntry.value)), __jsx(Box, null, "TTL:", ' ', __jsx(Text, {
    as: "span",
    fontWeight: "bold"
  }, "3600 (or default)")))))), __jsx(Box, {
    mb: 5
  }, __jsx(Text, {
    color: "gray.700",
    size: "sm"
  }, __jsx(Text, {
    as: "span",
    fontWeight: "bold"
  }, "Note:"), ' ', "It can take up to", ' ', __jsx(Text, {
    as: "span",
    fontWeight: "bold"
  }, "24 hours"), ' ', "before your domain is pointed at Hatch by your registrar. During this time, you will see a \u201Cverifying\u201D status for any project you publish to this domain. Once the verifying process is complete, we will take care of the rest and your site will be live!")), __jsx(Box, null, __jsx(Link, {
    href: "/faqs",
    textDecor: "underline",
    fontWeight: "bold",
    target: '_blank'
  }, "get help")))), Footer));
}