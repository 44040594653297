import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMultiStyleConfig } from '@chakra-ui/react';
import { Box } from '../Box';
import { Button } from '../Button';
import { Logo } from '../Icon';
import { Text } from '../Text';
// TODO: Figma says text small but fontSize is different
export const InformationalUpgradeCTA = ({ heading, body, variant, isProMember = false, onClick, }) => {
    const styles = useMultiStyleConfig('InformationalUpgradeCTA', { variant });
    return (_jsxs(Button, { __css: styles.box, onClick: onClick, children: [_jsxs(Box, { children: [_jsx(Text, { size: 'sm', fontWeight: 'bold', fontSize: '14px', children: heading }), _jsx(Text, { as: 'div', size: 'xs', children: body })] }), _jsx(Box, { __css: styles.logoWrap, children: _jsx(Logo, { w: '14', h: '14', fill: 'gray.800', pl: 2 }) })] }));
};
